.login-image{
  position: absolute;
  top: 0 ;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.swirl{
  position: absolute;
  inset: 0;
  overflow: hidden;
  z-index: -1;

  & img{
    transform: rotate(90deg) translate(4%, 40%);
    width: 220vh;
    height:auto;
    opacity: 0.16;
  }
}