.flex {
  display: flex;
  align-items: start;
  justify-content: start;
  max-width: 100%;
  width: 100vw;
}

.sidebar {
  width: 0;
  height: 100vh;
  position: sticky;
  top: 0;
  overflow: hidden;

  & .child-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}

.content {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}
