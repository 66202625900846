.placeholder {
  height: 100%;
  width: 100%;
  background-color: var(--color-brand-primary-200);
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 500;
  color: var(--color-brand-primary-700);
  padding: 32px;
}

.small {
  max-width: 40px;
  max-height: 40px;
  font-size: 8px;
  height: 40px;
  line-height: 8px;
}
