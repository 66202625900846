.header {
  background-color: var(--color-brand-primary-900);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  height: 288px;
}

.go-back-container {
  color: var(--color-brand-primary-400);
  height: 40px;
  display: flex;
  gap: 8px;
  cursor: pointer;
}

.swirl {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 0;
  width: 1280px;
  pointer-events: none;

  & img {
    transform: translateX(50%);
    width: 1280px;
    height: auto;
    opacity: 0.16;
  }
}

.wrapper {
  grid-column: 2 / -2;
  padding-block: 56px 132px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  @media (width < 769px) {
    flex-direction: column;
    align-items: flex-start;

    & > div:last-of-type {
      margin-top: 16px;
    }
  }

  &.has-back-button {
    padding-block: 0 132px;
  }
}

.art-consultant-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.header-metadata {
  margin-block-start: 8px;
  display: flex;
  flex-flow: column nowrap;
  gap: 2px;
}

.metadata-item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 6px;
  justify-content: flex-start;
  color: var(--color-brand-primary-400);
}

.proposal-title-container {
  display: flex;
  align-items: center;
  gap: 8;
}
