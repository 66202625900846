.card {
  height: auto;
  width: 100%;
  margin-block-end: 48px;
}

.metas {
  gap: 2px;
  margin-block-start: 4px;
  display: flex;
  flex-flow: column nowrap;
}

.meta {
  display: flex;
  flex-flow: row nowrap;
  gap: 6px;
  justify-content: flex-start;
  align-items: center;
  color: var(--color-neutral-500);
}

.meta svg {
  min-width: 24px;
  min-height: 24px;
}

.image-wrapper {
  display: grid;
  grid-template-columns: 1fr;
}

.image-wrapper div {
  display: grid;
  grid-area: -1 / 1;
  justify-self: end;
}

.image-wrapper div svg {
  z-index: 5;
  justify-self: end;
  color: white;
  margin-top: 12px;
  margin-right: 12px;
  cursor: pointer;
}

.displayable-image {
  grid-area: 1 / 1;
  aspect-ratio: 1 / 1;
  object-fit: contain;
  object-position: center center;
  overflow: hidden;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.image-wrapper img {
  grid-area: 1 / 1;
  aspect-ratio: 1 / 1;
  background: var(--color-brand-primary-200, #eff1f6);
  border-radius: 16px;
  border: 1px solid rgb(0 0 0 / 8%);
  object-fit: contain;
  object-position: center center;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

/** For when there is no image available. */
.image-wrapper div div {
  background: var(--color-brand-primary-200, #eff1f6);
  border-radius: 16px;
  border: 1px solid rgb(0 0 0 / 8%);
}
