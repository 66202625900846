.title {
  margin-block: 10px 30px;
}

.proposal-info-container {
  margin-left: 16px;
  margin-right: 16px;
  height: 100%;
  border-right: 1px solid var(--color-neutral-300);
  padding-left: 16px;
  padding-right: 16px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.proposal-metas-container {
  gap: 2px;
  margin-block-start: 4px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.proposal-meta {
  display: flex;
  flex-flow: row nowrap;
  gap: 6px;
  justify-content: flex-start;
  align-items: center;
  color: var(--color-neutral-500);
}

.is-saved-container {
  display: flex;
  gap: 6px;
  justify-content: end;
}

.proposal-meta svg {
  color: var(--color-brand-quarternary-300);
}

.proposal-line-card {
  margin-block-end: 24px;
}

.proposal-line-content {
  display: flex;
  min-height: fit-content;
  gap: 8px;
  margin-block-end: 16px;
}

.proposal-line-quick-menu {
  margin-left: auto;
}

.proposal-line-cover {
  background: var(--color-brand-primary-200, #eff1f6);
  border-radius: 16px;
  border: 1px solid rgb(0 0 0 / 8%);
  overflow: hidden;
  width: 128px;
  min-width: 128px;
  height: 128px;
  cursor: pointer;
}

.proposal-line-cover img {
  grid-area: 1 / 1;
  aspect-ratio: 1 / 1;
  object-fit: contain;
  object-position: center center;
  overflow: hidden;
  width: 128px;
  height: 128px;
}

.proposal-line-content .metas .meta svg {
  color: var(--color-neutral-500);
}

.metas {
  display: flex;
  flex-direction: column;
  justify-items: center;
}

.meta {
  display: flex;
  gap: 3px;
}

.proposal-line-note-container {
  display: flex;
}
