.customer-site {
  grid-column: span 1;
  position: relative;
}

.custom-grid {
  grid-column: 1 / -1;
  grid-template-columns: repeat(auto-fit, minmax(164px, 214px));
  display: grid;
  grid-gap: 1rem;
  align-items: start;

  @media (width < 769px) {
    grid-template-columns: repeat(2, minmax(164px, 214px));
  }
}
