.page-content{
  padding-block-start: 40px;
}

.title{
  grid-column: 1 / -1;
}

.collage{
  width: 100%;
  aspect-ratio: 1 / 1;
  position: relative;
  display: grid;
  overflow: hidden;
  border-radius: 16px;
  grid-gap:1px;
  border: 1px solid var(--neutral-300-a-8, rgb(0 0 0 / 8%));
  grid-template-areas: 
    "a b"
    "a c";

  & > div{
    position: relative;
    overflow: hidden;

    &:nth-child(1){
      grid-area: a;
      aspect-ratio: 1 / 2;
    }

    &:nth-child(2){
      grid-area: b;
      aspect-ratio: 1 / 1

    }

    &:nth-child(3){
      aspect-ratio: 1 / 1;
      grid-area: c;
      position: relative;
    }

    & img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }
}

.overlay{
  position: absolute;
  inset: 0;
  backdrop-filter: blur(12px);
  display: grid;
  place-items: center;
}

.link{
  text-decoration: inherit;
  color: inherit;
}

.metas{
  gap: 2px;
  margin-block-start: 4px;
  display: flex;
  flex-flow: column nowrap;
}

.meta{
  display: flex;
  flex-flow: row nowrap;
  gap: 6px;
  justify-content: flex-start;
  align-items: center;
  color: var(--color-neutral-500);
}

.icon{
  width: 16px;
}