.custom-grid {
  grid-column: 1 / -1;
  grid-template-columns: repeat(auto-fit, minmax(164px, 214px));
  display: grid;
  grid-gap: 1rem;
  align-items: start;
}

.sidebar {
  display: sticky !important;
}

.quick-action-list {
  display: flex;
  height: 40px;
  gap: 16px;
}

.filter-button {
  padding-left: 16px;
  display: flex;
  gap: 8px;
}

.no-results-container {
  grid-column: 1 / -1;
  place-content: center;
  max-width: 400px;
  justify-self: center;
}
