@font-face {
  font-family: Calibre;
  src:
    url("/public/fonts/Calibre-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Calibre;
  src:
    url("/public/fonts/Calibre-RegularItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Calibre;
  src:
    url("/public/fonts/Calibre-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Calibre;
  src:
    url("/public/fonts/Calibre-MediumItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Platform;
  src:
    url("/public/fonts/Platform-Regular.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Platform;
  src:
    url("/public/fonts/Platform-RegularItalic.otf") format("opentype");
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Platform;
  src:
    url("/public/fonts/Platform-Medium.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Platform;
  src:
    url("/public/fonts/Platform-MediumItalic.otf") format("opentype");
  font-style: italic;
  font-weight: 500;
  font-display: swap;
}

:root{
  --color-neutral-900: #000;
  --color-neutral-600: #4b4b4b;
  --color-neutral-500: #949494 ;
  --color-neutral-400: #ccc;
  --color-neutral-300: #00000014;
  --color-neutral-200: #eff1f6;
  --color-neutral-100: #fff;
  --color-neutral-100a8: #ffffff14;
  --color-brand-primary-900: #0a1629;
  --color-brand-primary-700: #1a396e;
  --color-brand-primary-400: #a1bbe8;
  --color-brand-primary-200: #eff1f6;
  --color-brand-primary-100: #f5f7fb;
  --color-brand-secondary-300: #afd1f0;
  --color-brand-tertiary-300: #ffcdc6;
  --color-brand-quarternary-300: #ff943b;
  --color-signal-error-900: #490817;
  --color-signal-error-700: #b71538;
  --color-signal-error-200: #f8dfe5;
  --color-signal-warning-900: #5c2a00;
  --color-signal-warning-400: #ff943b;
  --color-signal-warning-200: #fbf2df;
  --color-signal-success-900: #0b4626;
  --color-signal-success-700: #1d7c48;
  --color-signal-success-200: #e0faec;
  --color-signal-active-900: #0a1629;
  --color-signal-active-700: #1a396e;
  --color-signal-active-200: #eaf0fa;
}

*, *::before, *::after {
  box-sizing: border-box;
}

html, body{
  min-height: 100vh;
  height: 100vh;
  margin: 0;
  padding: 0;
}

#root{
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: Calibre, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page-wrapper{
  padding-block-start:64px;
  background-color: var(--color-brand-primary-100);
  min-height: 100dvh;
}