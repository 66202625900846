.quick-action-list {
  display: flex;
  height: 40px;
  gap: 16px;
}

.proposals-table {
  grid-column: span 12;
}

.clickable-cell {
  cursor: pointer;
}
