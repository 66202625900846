.nav{
  height: 64px;
  background-color: var(--color-brand-primary-900);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  z-index: 9;
}

.wrapper{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  grid-column: 1 / -1;
}