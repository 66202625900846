.grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(200px, 300px), 1fr));
  grid-gap: 24px;

  @media (width < 769px) {
    grid-template-columns: repeat(2, minmax(164px, 214px));
  }
}

.grid > div {
  border-radius: 12px;
}

.card {
  height: auto;
  width: 100%;
  margin-block-end: 48px;
}

.metas {
  gap: 2px;
  margin-block-start: 4px;
  display: flex;
  flex-flow: column nowrap;
}

.meta {
  display: flex;
  flex-flow: row nowrap;
  gap: 6px;
  justify-content: flex-start;
  align-items: center;
  color: var(--color-neutral-500);
}

.icon {
  width: 16px;
}

.image-wrapper {
  aspect-ratio: 1/1;
  width: 100%;
  border-radius: 16px;
  border: 1px solid rgb(0 0 0 / 8%);
  background: var(--color-brand-primary-200, #eff1f6);
  overflow: hidden;
  cursor: pointer;

  & img {
    object-fit: contain;
    object-position: center center;
    width: 100%;
    height: 100%;
  }
}
