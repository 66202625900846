.filter-box {
  height: 100%;
  border-right: 1px solid var(--color-neutral-300);
  padding-left: 16px;
  padding-right: 16px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.artist-search-container {
  display: flex;
  gap: 8px;
  color: var(--color-brand-primary-700);
}

.filter-box::-webkit-scrollbar {
  display: none;
}

.title {
  margin-block: 10px 30px;
}

.content-box {
  grid-column: 1/-1;
}

.slider-input-fields {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.filter-list {
  margin-block-end: 32px;
}

.filter-label {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  color: var(--color-neutral-900) !important;
  margin-bottom: 8px;
}

.filter-close-container {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;

  @media (width < 576px) {
    display: block;
  }

  button {
    width: 100%;
  }
}
