.custom-grid {
  grid-column: 1 / -1;
  grid-template-columns: repeat(auto-fit, minmax(164px, 214px));
  display: grid;
  grid-gap: 1rem;
  align-items: start;
}

.content-box {
  grid-column: 1/-1;
}

.filter-button {
  padding-left: 16px;
  display: flex;
  gap: 8px;
}
